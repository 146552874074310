<template>
    <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
            <vx-card>
                <span>
                    <h4 class="mb-5" v-if="this.$route.params.id">Edit Reg. Price</h4>
                    <h4 class="mb-5" v-else>Create Reg. Price</h4>
                </span>
                <div class="vx-row mb-6" style="width:100%;">
                    <vs-button
                        class="ml-4 mt-2"
                        color="danger"
                        icon-pack="feather"
                        icon="icon-arrow-left"
                        @click="handleBack()"
                    >Back</vs-button>
                </div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Code</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input class="w-full" name="Code" v-model.lazy="data.code" readonly/>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Name</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input class="w-full" name="name" :value="data.name" @change="data.name = $event.target.value"/>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('name')"
                            >{{ errors.first('name') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Notes</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <!-- <vs-input class="w-full" name="Notes" :value="data.notes" @change="data.notes = $event.target.value"/> -->
                        <vs-textarea class="w-full" maxlength ="255" name="Notes" :value="data.notes" @change="data.notes = $event.target.value" />
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Model</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <ul class="">
                            <li>
                                <vs-radio name="model" v-model.lazy="data.model" vs-value="Final">Final</vs-radio>
                            </li>
                            <li>
                                <vs-radio name="model" v-model.lazy="data.model" vs-value="Step Ladder">Step Ladder</vs-radio>
                            </li>
                            <span
                                class="text-danger text-sm"
                                v-show="errors.has('model')"
                                >{{ errors.first('model') }}
                            </span>
                        </ul>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Valid From</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <datepicker :disabled="read" v-validate="'required'" name="valid from" :inline="false" v-model.lazy="data.valid_from" placeholder="Select Date"></datepicker>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('valid from')"
                            >{{ errors.first('valid from') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Valid To</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <datepicker :disabled="read" v-validate="'required'" name="valid to" :inline="false" v-model.lazy="data.valid_to" placeholder="Select Date"></datepicker>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('valid to')"
                            >{{ errors.first('valid to') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
                    <div class="vx-col sm:w-1/5 w-full">
                        <span>Currency Code</span>
                    </div>
                    <div class="vx-col sm:w-4/5 w-full">
                        <v-select v-validate="'required'" name="currency code" v-model.lazy="data.currency_code" label="Code" placeholder="Select currency code" :options="currency" @input="setSelectedCurrencyCode">
                            <template slot="option" slot-scope="option">
                                <div style="align-items: center;"><span>{{option.Code}}</span></div>
                            </template>
                        </v-select>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('currency code')"
                            >{{ errors.first('currency code') }}
                        </span>
                    </div>
                </div>
                <div class="vx-row mb-6 ml-4 mr-4" v-if="this.$route.params.id == null">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Copy From</span>
					</div>
                    <div class="vx-col sm:w-4/5 w-full">
                        <v-select
                            @input="selectedPriceList"
                            :options="price.copy_value"
                            placeholder="List price"
                            class="input-class"
                        />
                    </div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Items</span>
					</div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <!-- <template slot="header"> -->
                            <vs-dropdown vs-trigger-click class="cursor-pointer">
                                <div
                                    class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                                >
                                    <span class="mr-2">{{this.table.start}} - {{this.table.end}} of {{this.table.total}}</span>
                                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                                </div>
                                <vs-dropdown-menu>
                                    <vs-dropdown-item
                                    v-for="item in table.limits"
                                    :key="item"
                                    @click="handleChangelength(item)"
                                    >
                                    <span>{{item}}</span>
                                    </vs-dropdown-item>
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        <!-- </template> -->
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-pagination style="padding-top:5px" :total="table.totalPage" v-model="setPage" />
                    </div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-5/5 w-full mb-2" v-for="(tr, indextr) in items.slice(table.start-1, table.end)" :key="indextr">
                        <div class="vx-row mb-2" id="itemsHolder">
                            <div class="vx-col w-2/5" style="display: flex">
                                <span style="margin: auto; width: 5%" class="mr-3" v-if="items[indextr + table.start - 1].num != 0" >{{items[indextr + table.start - 1].num}}.</span>
                                <span style="margin: auto; width: 5%" class="mr-3" v-else></span>
                                <vs-input label="Item" class="w-full" :value="items[indextr + table.start - 1].label" readonly/>
                            </div>
                            <div class="vx-col w-2/5" style="display: flex">
                                <div class="vx-col w-1/3 mr-1">
                                    <vs-input @keypress="isNumber($event)" label="Qty From" :value="items[indextr + table.start - 1].qty_from" @change="items[indextr + table.start - 1].qty_from = $event.target.value" class="w-full" type="number" min="0" name="QtyFrom"/>
                                </div>
                                <div class="vx-col w-1/3 mr-1">
                                    <vs-input @keypress="isNumber($event)" label="Qty To" :value="items[indextr + table.start - 1].qty_to" @change="items[indextr + table.start - 1].qty_to = $event.target.value" class="w-full" type="number" min="0" name="QtyTo"/>
                                </div>
                                <div class="vx-col w-1/3">
                                    <vx-input-group>
                                        <template slot="append">
                                            <div class="append-text bg-primary" style="margin-top: 19px">
                                                <span>{{data.currency_code}}</span>
                                            </div>
                                        </template>
                                        <vs-input @keypress="isNumber($event)" label="Price" :value="items[indextr + table.start - 1].price" @change="items[indextr + table.start - 1].price = $event.target.value" class="w-full" name="Price"/>
                                    </vx-input-group>
                                </div>
                            </div>
                            <div class="vx-col w-1/5" style="display: flex">
                               <vs-button
                                    v-if="items[indextr + table.start - 1].status=='minus'"
                                    style="display: table-cell;"
                                    v-on:click="handleRemoveItem(indextr + table.start - 1)"
                                    color="danger"
                                    type="filled"
                                    icon-pack="feather"
                                    icon="icon-minus"
                                ></vs-button>
                                <vs-button
                                    v-else
                                    style="display: table-cell;"
                                    v-on:click="handleAddItem(indextr + table.start - 1, tr)"
                                    color="success"
                                    type="filled"
                                    icon-pack="feather"
                                    icon="icon-plus"
                                ></vs-button>
                            </div>
                        </div>
                    </div>
				</div>
                <!-- logic items.length < 50 dipakai untuk tetap menampilkan holder, minimal sampai 50 data telah dimasukkan ke this.items -->
                <content-placeholders v-if="statusPlaceholder == true || items.length < 50" class="mb-6 ml-4 mr-4">
                    <div class="vx-col sm:w-5/5 w-full mb-2">
                        <div class="vx-row mb-2">
                            <div class="vx-col w-2/5" style="display: flex">
                                <content-placeholders-text style="margin: auto; width: 5%" class="ml-4 mr-3" :lines="1" />
                                <content-placeholders-text class="w-full" :lines="2" />
                            </div>
                            <div class="vx-col w-2/5" style="display: flex">
                                <div class="vx-col w-1/3 mr-1">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                                <div class="vx-col w-1/3 mr-1">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                                <div class="vx-col w-1/3">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                            </div>
                            <div class="vx-col w-1/5" style="display: flex">
                                <div class="vx-col w-1/2">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                                <!-- <div class="vx-col w-1/2" style="text-align: center; margin: auto; margin-bottom: 0">
                                    <content-placeholders-text class="w-full" :lines="3" />
                                </div> -->
                            </div>
                        </div>
                    </div>
                </content-placeholders>
                <!-- <div v-else>Loading Data</div> -->

                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col">
						<vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
					</div>
				</div>
                
            </vx-card>
        </div>
    </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";

export default {
    components: {
        Datepicker,
        vSelect
    },
    created() {
        this.getDataListPrice()
        this.getCurrencyCode()
    },
    data() {
        return {
            read: false,
            price:{
                copy_id: null,
                copy_name: null,
                copy_value: []
            },
            currency: [],
            items: [],
            itemsResp: [],
            data: {
                code: "",
                name: "",
                notes: "",
                model: false,
                valid_from: "",
                valid_to: "",
                currency_code: ""
            },
            statusPlaceholder: true,
            table: {
                length: 25,
                page: 1,
                total: 0,
                totalPage: 0,
                limits: [25, 50, 100, "All"],
                start: 1,
                end: 0
            },
        };
    },
    methods: {
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        handleRemoveItem(indextr){
            this.items.splice(indextr, 1)
        },
        set(v){
            // console.log(v)
        },
        handleBack() {
            this.$router.push("/master/pricing-management-v2");
        },
        getCurrencyCode(){
            this.$http
            .get("/api/v1/master/currency").then(resp => {
                if (resp.status == "success") {
                    this.currency = resp.data.records;
                }
            });
        },
        setSelectedCurrencyCode(value) {
            this.data.currency_code = value.Code;
        },
        getCode() {
        this.$vs.loading();
            this.$http
            .get("/api/v1/master/price/code")
            .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.data.code = resp.data
                    // this.getItemUnit();
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
                
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        getDataListPrice() {
        this.$vs.loading();
        this.$http
            .get("/api/v1/master/prices")
            .then(resp => {
            if (resp.status == "success") {
                var _this = this
                resp.data.records.forEach(function(element){
                    _this.price.copy_value.push({
                        id: element.id,
                        label: element.code + " - " + element.name
                    })
                })

                this.$vs.loading.close();
            }
            });
        },
        getItemUnit() {
        this.$vs.loading();
        // console.log("hit ini", "/api/v1/master/item-unit")
            this.$http
            .get("/api/v1/master/item-unit")
            .then(resp => {
                // console.log("Perlu diset pagination manual juga disini",resp)
                this.$vs.loading.close();
                var _this = this
                if (resp.code == 200) {
                    // Save respon di local var, untuk dimainkan
                    _this.itemsResp = resp.data;
                    // Test Loop sedikit data, 20 items
                    // for(var k = 0; k < 60; k++){
                    // Loop all data, ribuan data items
                    for(var k = 0; k < resp.data.length; k++){
                        _this.items.push({
                            num: k+1,
                            label: resp.data[k].label,
                            item_name: resp.data[k].item_name,
                            sku_code: resp.data[k].sku_code,
                            item_unit: resp.data[k].item_unit,
                            qty_from: "0",
                            qty_to: "0",
                            price: "0",
                            discount: false,
                            status: "plus"
                        })
                    }
                    
                    // pembatasan 50 data, agar load view UI tidak berat
                    if(_this.items.length > 50){
                        _this.statusPlaceholder = false

                        // =======================================
                        _this.table.total = resp.data.length;
                        // console.log(_this.table.total, resp.data.length)
                        _this.table.totalPage = resp.data.length % _this.table.length > 0 ? Math.floor(resp.data.length / _this.table.length) + 1 : Math.floor(resp.data.length / _this.table.length);
                        _this.setStartEnd();
                        // =======================================
                    }
                    // Kondisi saat loop all data
                    if(_this.items.length == resp.data.length){
                        _this.statusPlaceholder = false
                    }
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        selectedPriceList(val){
            this.$http
            .get("/api/v1/master/price/" + val.id)
            .then(resp => {
                this.items = []
                var _this = this
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.statusPlaceholder = true
                    
                    setTimeout(function(){
                        for(var k = 0; k < resp.data.list.length; k++){
                            _this.items.push({
                                num: resp.data.list[k].num,
                                label: resp.data.list[k].label,
                                status: resp.data.list[k].status,
                                qty_from: resp.data.list[k].qty_from.toString(),
                                qty_to: resp.data.list[k].qty_to.toString(),
                                price: resp.data.list[k].price.toString(),
                                discount: resp.data.list[k].discount,
                                sku_code: resp.data.list[k].sku_code,
                                item_unit: resp.data.list[k].item_unit,
                                item_name: resp.data.list[k].item_name,
                            })
                            
                        }
                        if(_this.items.length == resp.data.list.length){
                            _this.statusPlaceholder = false
                            _this.getNewItem(val.id)
                        }
                    }, 0); 

                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });

        },
        handleSubmit() {
            this.$validator.validateAll().then(result => {
                var _this = this
                if(this.data.name == ""){
                    this.errors.add({
                        field: "name",
                        msg: "The name is required"
                    });
                    result = false;
                    // alert("sini 481")
                }
                if(this.data.model == false){
                    this.errors.add({
                        field: "model",
                        msg: "The model is required"
                    });
                    result = false;
                    // alert("sini 489")
                }
                // console.log("this.items.length", this.items.length)
                // console.log("this.itemsResp.length", this.itemsResp.length)
                if(this.items.length < this.itemsResp.length){
                    // Dimasukkan sini, apabila items.length (yang dipush u/ dirender di UI) belum sama dengan total item, maka ada alert
                    // "Harap set harga untuk semua items"
                    // alert("sini 492")
                    // alert("this.items.length < this.itemsResp.length")
                    // this.$vs.notify({
                    //     color: "danger",
                    //     title: "Error",
                    //     text: "A <br> B",
                    //     position: "top-right",
                    //     iconPack: "feather",
                    //     icon: "icon-x-circle"
                    // });
                    result = false;
                }
                if (result) {
                    this.$vs.loading();
                    this.items.forEach(function(element, index){
                        _this.items[index].qty_from = element.qty_from.toString()
                        _this.items[index].qty_to = element.qty_to.toString()
                        // _this.items[index].price = parseFloat(element.price)
                        _this.items[index].price = element.price.toString()
                    })
                    if (this.$route.params.id) {
                        // console.log(this.paramData())
                        this.putData();
                    } else {
                        this.postData();
                        // console.log(this.paramData())
                    }
                }
            });
        },
        paramData() {
            // let sedikit_item = this.items.slice(0,6)
            // console.log(sedikit_item)
            return {
                price_id: parseInt(this.$route.params.id),
                code: this.data.code,
                name: this.data.name,
                notes: this.data.notes,
                model: this.data.model,
                valid_from: this.data.valid_from,
                valid_to: this.data.valid_to,
                currency_code: this.data.currency_code,
                item: this.items
                // item: sedikit_item
            };
        },
        postData() {
            this.$http
                .post("/api/v1/master/price", this.paramData())
                .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.handleClose();
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: "New List Price Created",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                    this.$router.push("/master/pricing-management-v2");
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        putData() {
        this.$http
            .put("/api/v1/master/price/" + this.$route.params.id, this.paramData())
            .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
                this.handleClose();
                this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: "List Price Updated",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        handleClose() {
            window.scrollTo(0, 0);
            this.$emit("close");
        },
        handleAddItem(indextr, tr){
            this.items.splice(indextr+1, 0, {
                num: 0,
                label: tr.label,
                item_name: tr.item_name,
                sku_code: tr.sku_code,
                item_unit: tr.item_unit,
                qty_from: "0",
                qty_to: "0",
                price: "0",
                discount: false,
                status: "minus",
            })
        },
        getData() {
        this.$vs.loading();
            this.$http
            .get("/api/v1/master/price/" + this.$route.params.id)
            .then(resp => {
                var _this = this
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.data.code = resp.data.code;
                    this.data.name = resp.data.name;
                    this.data.notes = resp.data.notes;
                    this.data.model = resp.data.model;
                    this.data.valid_from = resp.data.valid_from;
                    this.data.valid_to = resp.data.valid_to;
                    this.data.currency_code = resp.data.currency_code;
                    this.read = (resp.data.price_rule_usage > 0) ? true : false;

                    // this.getData1()
                    this.statusPlaceholder = true
                    
                    setTimeout(function(){
                        for(var k = 0; k < resp.data.list.length; k++){
                            _this.items.push({
                                num: resp.data.list[k].num,
                                label: resp.data.list[k].label,
                                status: resp.data.list[k].status,
                                qty_from: resp.data.list[k].qty_from.toString(),
                                qty_to: resp.data.list[k].qty_to.toString(),
                                price: resp.data.list[k].price.toString(),
                                discount: resp.data.list[k].discount,
                                sku_code: resp.data.list[k].sku_code,
                                item_unit: resp.data.list[k].item_unit,
                                item_name: resp.data.list[k].item_name,
                            })
                            
                        }
                        if(_this.items.length == resp.data.list.length){
                            _this.statusPlaceholder = false
                            _this.getNewItem(_this.$route.params.id)
                        }
                    }, 0); 

                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        getNewItem(val) {
            this.$http
            .get("/api/v1/master/new-item-unit/" + val)
            .then(resp => {
                // console.log("Perlu diset pagination manual juga disini",resp)
                var _this = this
                this.$vs.loading.close();
                if (resp.code == 200) {
                    // Save respon di local var, untuk dimainkan
                    _this.itemsResp = resp.data;
                    
                    setTimeout(function(){
                        var x = 0 // jumlah items yang telah ada sebelumnya
                        _this.items.forEach(function(element){
                            if(element.num != 0){
                                x++
                            }
                        })
                        
                        for(var k = 0; k < resp.data.length; k++){
                            _this.items.push({
                                num: x+k+1,
                                label: resp.data[k].label,
                                item_name: resp.data[k].item_name,
                                sku_code: resp.data[k].sku_code,
                                item_unit: resp.data[k].item_unit,
                                qty_from: "0",
                                qty_to: "0",
                                price: "0",
                                discount: false,
                                status: "plus"
                            })
                            
                        }
                        
                        // pembatasan 50 data, agar load view UI tidak berat, data lainnya masih lanjut proses dimasukkan ke this.items
                        if(_this.items.length > 50){
                            _this.statusPlaceholder = false

                            // =======================================
                            _this.table.total = resp.data.length + x;
                            // console.log("_this.table.total", _this.table.total)
                            // console.log("resp.data.length", resp.data.length)
                            // console.log("x - jml items before", x)
                            _this.table.totalPage = (resp.data.length + x) % _this.table.length > 0 ? Math.floor((resp.data.length+x) / _this.table.length) + 1 : Math.floor((resp.data.length+x) / _this.table.length);
                            _this.setStartEnd();
                            // =======================================
                        }

                        if(_this.items.length == resp.data.length){
                            _this.statusPlaceholder = false
                        }
                    }, 0); 

                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        cit() {
        this.$vs.loading();
            this.$http
            .get("/api/v1/master/item-unit")
            .then(resp => {
                this.$vs.loading.close();
                var _this = this
                if (resp.code == 200) {
                    for(var k = 0; k < resp.data.length; k++){
                        var unit = resp.data[k].item_unit;
                        var p = []
                        if(unit == 'Pcs'){
                            p = [8000, 7000, 6000]
                        } else if(unit == 'Bal') {
                            p = [40000, 39000, 38000]
                        } else if(unit == 'Carton') {
                            p = [250000, 240000, 230000]
                        } else if(unit == 'Dus 1000') {
                            p = [350000, 340000, 330000]
                        } else if(unit == 'Pack 50') {
                            p = [50000, 49000, 48000]
                        } else if(unit == 'Bag 120') {
                            p = [150000, 140000, 130000]
                        } else if(unit == 'Bag 20') {
                            p = [70000, 69000, 67000]
                        } else if(unit == 'Bal 50') {
                            p = [90000, 89000, 86000]
                        } else if(unit == 'Pack 10') {
                            p = [20000, 19000, 18000]
                        } else if(unit == 'Dus 40') {
                            p = [50000, 49000, 47000]
                        } else if(unit == 'Pack 5') {
                            p = [10000, 9000, 8000]
                        } else if(unit == 'Dus 72') {
                            p = [120000, 119000, 118000]
                        } else if(unit == 'Dus 12') {
                            p = [34000, 33000, 32000]
                        } else if(unit == 'Bag') {
                            p = [9000, 8000, 7000]
                        } else if(unit == 'Dus 20') {
                            p = [54000, 53000, 51000]
                        } else {
                            p = [0, 0, 0]
                        }
                        
                        var qtyf = [1, 1001, 50001]
                        var qtyt = [1000, 50000, 999999999]
                        var status = ['plus', 'minus', 'minus']
                        var num = [(k+1), '', '']

                        for(var i = 0; i < 3; i++){
                            _this.items.push({
                                num: num[i],
                                label: resp.data[k].label,
                                item_name: resp.data[k].item_name,
                                sku_code: resp.data[k].sku_code,
                                item_unit: resp.data[k].item_unit,
                                qty_from: qtyf[i],
                                qty_to: qtyt[i],
                                price: p[i],
                                discount: true,
                                status: status[i]
                            })
                        }
                    }
                    _this.statusPlaceholder = false
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;

            // checkStartEnd, kalau kalkulasi melebihi jumlah total data, maka this.table.page akan diset default ke 1
            let valStart = this.table.length * this.table.page - this.table.length + 1;
            let valEnd = this.table.length * this.table.page;

            if(valEnd > this.table.total && valStart <= valEnd){
                this.table.page = 1;
            }

            this.setStartEnd();
        },
        handleChangePage(page) {
            this.table.page = page;
            // console.log("handleChangePage(page)", page)
            this.setStartEnd();
            // this.getData();
        },
        setStartEnd() {
            this.table.totalPage = this.items.length % this.table.length > 0 ? Math.floor(this.items.length / this.table.length) + 1 : Math.floor(this.items.length / this.table.length);

            let valStart = this.table.length * this.table.page - this.table.length + 1;
            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }

            let valEnd = this.table.length * this.table.page;
            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
            // console.log("table.start-1", this.table.start-1);
            // console.log("table.end", this.table.end);
            // console.log(
            //     this.items.slice(valStart-1, valEnd)
            // )
        },
    },
    mounted() {
        if(!(this.$route.params.id)){
            if (this.$route.query.cit){
                this.getCode();
                this.cit()
            } else {
                this.getCode();
                this.getItemUnit();
            }   

            // this.getCode();
            // this.getItemUnit();
        } else {
            this.getData();
        }
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
                this.setStartEnd();
            }
        }
    },
    watch: {
        "data.name": function(val) {
            if(val == ""){
                this.errors.add({
                    field: "name",
                    msg: "The name is required"
                });
            } else {
                this.errors.clear()
            }
        },
        "data.model": function(val) {
            if(val == false){
                this.errors.add({
                    field: "model",
                    msg: "The model is required"
                });
            } else {
                this.errors.clear()
            }
        },
    }
};
</script>